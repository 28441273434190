






































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import { SpeedSectionSearch, RouteStopSearch } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";
import { Route } from "@/store/models/route";

@Component({
  components: {},
})
export default class RouteDetailView extends Vue {
  section: SpeedSectionSearch = {} as SpeedSectionSearch;
  stop: RouteStopSearch = {} as RouteStopSearch;
  route: Route = {} as Route;

  async created() {
    await metaStore.loadRoutesDD();
    if (this.$route.query) {
      const id: any = this.$route.query.id;
      await metaStore.getRouteById(id);
      this.route = metaStore.route;
      this.section.road = id;
      this.stop.route = parseInt(id);
      await metaStore.searchSpeedSection(this.section);
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  async handleClick(e: any) {
    if (e.index === "0") {
      await metaStore.searchSpeedSection(this.section);
    } else {
      await metaStore.getRouteStop(this.stop);
    }
  }

  get speedSectionList() {
    return metaStore.speedSectionList;
  }

  get stopList() {
    const list = metaStore.routeStopList.sort((a, b) => a.id - b.id);
    return list;
  }
}
